@use "src/_app/styles/mixins.scss" as *;
.blog__table {
  border: 1px solid var(--border_1);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  color: var(--primary);
  margin: 40px auto;

  @include respond(tabletS) {
    border: none;
    row-gap: 12px;
    margin: 24px auto;
  }

  tbody {
    display: flex;
    flex-direction: column;

    @include respond(tabletS) {
      row-gap: 12px;
    }
  }

  thead {
    border-bottom: 1px solid var(--border_1);
    background: var(--bg_2);
    color: var(--text_1);
    border-radius: 16px 16px 0 0;

    @include respond(tabletS) {
      display: none;
    }

    th {
      text-align: start;

      @include tSubtitle;
    }
  }

  tr {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    padding: 16px 24px;
    width: 100%;
    height: 100%;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border_1);
    }

    @include respond(tabletS) {
      border: 1px solid var(--border_1);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      padding: 16px 0 0;
      overflow: hidden;
      max-height: 60px;
      cursor: pointer;

      &.open {
        max-height: 999px;

        td:first-child {
          border-bottom: 1px solid var(--border_1);
        }

        .arrow {
          transform: rotate(180deg);
        }
      }
    }

    .table__cell:not(.empty) {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      text-align: start;

      @include tSubtitle;
      overflow: hidden;

      @include respond(tabletS) {
        overflow: visible;
        padding: 0 16px;

        &:first-child {
          padding-bottom: 16px;
        }

        &:not(:first-child) {
          padding: 16px;
        }
      }

      a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 400;
      }

      strong {
        font-weight: 600;
      }

      span {
        @include tCaption;
        display: none;
        color: var(--text_1);

        @include respond(tabletS) {
          display: block;
        }
      }
    }

    .empty {
      @include respond(tabletS) {
        display: none;
        padding: 0;
      }
    }

    .arrow {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 22px;
      transform: rotateY(0);
      top: 16px;
      display: none;

      @include respond(tabletS) {
        display: block;
      }
    }
  }
}
