@use "src/_app/styles/mixins.scss" as *;
.question_form {
  max-width: 774px;
  background: var(--bg_6);
  border-radius: 16px;
  box-shadow:
    0 16px 32px rgb(37 33 41 / 8%),
    0 0 12px rgb(37 33 41 / 4%);
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  padding: 32px 25px 32px 24px;

  @include respond(tabletS) {
    padding: 24px 16px;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    max-width: 536px;
    color: var(--primary);
    margin: 0 0 24px;

    @include respond(tabletS) {
      max-width: none;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.with_error .inputs {
      row-gap: 32px;

      @include respond(tabletS) {
        row-gap: 0;
      }
    }

    .inputs {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px 15px;

      @include respond(tabletS) {
        grid-template-columns: 1fr;
      }

      .btn {
        height: 60px;
      }

      .checkbox_wrapper {
        display: grid;
        grid-column: 1 / 4;

        &_short {
          grid-column-end: 3;
        }

        @include respond(tabletS) {
          grid-column-end: 1;
          grid-row-start: 4;
          margin-bottom: 28px;
          max-width: 80%;
        }
      }

      .long_placeholder {
        &::placeholder {
          @include tCaption;
          font-weight: 300;
        }
      }
    }
  }
}
