@use "src/_app/styles/mixins.scss" as *;
.get_started_block {
  max-width: 774px;
  width: 100%;
  margin: auto;

  display: flex;
  justify-content: center;
  color: #fff;

  background: var(--bg_1);
  border-radius: 16px;
  padding: 0 16px;

  .content_wrapper {
    max-width: 501px;
    padding: 32px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 16px;

    @include respond(tabletS) {
      text-align: left;
      align-items: flex-start;
      padding: 24px 16px;
    }

    a.getStartedButton {
      display: flex;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0;

      @include respond(tabletS) {
        width: 80%;
      }
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.12px;
      margin: 0;
    }

    a.btn {
      display: flex;
      width: 170px;
      margin-top: 8px;

      @include respond(tablet) {
        max-width: 175px;
      }

      @include respond(tabletS) {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}
